import { useRouteError } from "react-router-dom";

import { ThemeProvider } from "@edgedb/common/hooks/useTheme";

import { ExternalLinkIcon } from "./ui/icons";
import styles from "./error.module.scss";

export class LazyLoadError extends Error {}

export default function ErrorBoundary() {
  const error = useRouteError();

  if (error instanceof LazyLoadError) {
    console.log(error);
    window.location.reload();
    return null;
  }

  return (
    <ThemeProvider>
      <div className={styles.errorPage}>
        <h1>Unexpected Application Error</h1>
        <div className={styles.message}>
          {error instanceof Error ? error.message : String(error)}
        </div>
        <a
          className={styles.supportLink}
          href={`https://edgedb.com/p/cloud-support`}
          target="_blank"
        >
          Submit support ticket
          <ExternalLinkIcon />
        </a>
      </div>
    </ThemeProvider>
  );
}
