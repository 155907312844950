export function BurgerMenuIcon() {
  return (
    <svg
      width="18"
      height="14"
      viewBox="-1 -1 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="2" rx="1" />
      <rect y="5" width="16" height="2" rx="1" />
      <rect y="10" width="16" height="2" rx="1" />
    </svg>
  );
}

export function DownArrowIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.29289 10.2929C7.68342 9.90237 8.31658 9.90237 8.70711 10.2929L12 13.5858L15.2929 10.2929C15.6834 9.90237 16.3166 9.90237 16.7071 10.2929C17.0976 10.6834 17.0976 11.3166 16.7071 11.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L7.29289 11.7071C6.90237 11.3166 6.90237 10.6834 7.29289 10.2929Z"
        fill="#999999"
      />
    </svg>
  );
}

export function ExternalLinkIcon() {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.25725 10.2427L15.7425 1.75742M15.7425 1.75742L15.7425 8.12138M15.7425 1.75742L9.37857 1.75742"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 12L13 16L1 16L1 4L5 4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function LogOutIcon() {
  return (
    <svg
      width="19"
      height="14"
      viewBox="0 0 19 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 7L1 7M1 7L5.5 11.5M1 7L5.5 2.5M11.5 1L17.5 1V13H11.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function DropdownIcon() {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.29297 0.292969C3.68359 -0.0976562 4.31641 -0.0976562 4.70703 0.292969L7.70703 3.29297C7.93652 3.52246 8.03125 3.83594 7.99121 4.13477C7.96289 4.34424 7.86816 4.54639 7.70703 4.70703C7.53613 4.87842 7.31836 4.97461 7.09473 4.99561C6.80859 5.02246 6.5127 4.92627 6.29297 4.70703L4 2.41455L1.70703 4.70703C1.31641 5.09766 0.683594 5.09766 0.292969 4.70703C-0.0976562 4.31689 -0.0976562 3.68359 0.292969 3.29297L3.29297 0.292969ZM4.70703 11.707L7.70703 8.70703C8.09766 8.31641 8.09766 7.68311 7.70703 7.29297C7.31641 6.90234 6.68359 6.90234 6.29297 7.29297L4 9.58545L1.70703 7.29297C1.31641 6.90234 0.683594 6.90234 0.292969 7.29297C0.117188 7.46875 0.0205078 7.69434 0.00292969 7.9248C-0.0185547 8.20508 0.078125 8.49268 0.292969 8.70703L3.29297 11.707C3.68359 12.0977 4.31641 12.0977 4.70703 11.707Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function HeaderOrgIcon() {
  return (
    <svg
      width="16"
      height="24"
      viewBox="0 0 16 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6" cy="8" r="3" stroke="currentColor" strokeWidth="2" />
      <path
        d="M11 19V17C11 15.3431 9.65685 14 8 14H4C2.34315 14 1 15.3431 1 17V19"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M13 14V14C13.6487 14 14.3146 14.199 14.6458 14.7568C14.8388 15.0819 15 15.5023 15 16C15 17.5 15 19 15 19"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M12 5.1709C13.1652 5.58273 14 6.69397 14 8.00019C14 9.30641 13.1652 10.4177 12 10.8295"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

export function GithubIcon() {
  return (
    <svg viewBox="0 0 20 20" fill="currentColor">
      <path d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"></path>
    </svg>
  );
}

export function VerticalEllipsisIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25 4.75C8.25 3.92157 8.92157 3.25 9.75 3.25C10.5784 3.25 11.25 3.92157 11.25 4.75C11.25 5.57843 10.5784 6.25 9.75 6.25C8.92157 6.25 8.25 5.57843 8.25 4.75ZM8.25 10C8.25 9.17157 8.92157 8.5 9.75 8.5C10.5784 8.5 11.25 9.17157 11.25 10C11.25 10.8284 10.5784 11.5 9.75 11.5C8.92157 11.5 8.25 10.8284 8.25 10ZM8.25 15.25C8.25 14.4216 8.92157 13.75 9.75 13.75C10.5784 13.75 11.25 14.4216 11.25 15.25C11.25 16.0784 10.5784 16.75 9.75 16.75C8.92157 16.75 8.25 16.0784 8.25 15.25Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function SecretKeyIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="15.182"
        cy="8.31802"
        r="4"
        transform="rotate(45 15.182 8.31802)"
        stroke="currentColor"
        strokeWidth="2"
      />
      <circle
        cx="15.182"
        cy="8.31802"
        r="1"
        transform="rotate(45 15.182 8.31802)"
        fill="currentColor"
      />
      <path
        d="M11.6465 11.8535L5.98963 17.5104"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M3.16113 14.682L5.98956 17.5104"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M6.69678 12.5607L8.8181 14.682"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

export function GetStartIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="4"
        y="4"
        width="16"
        height="16"
        rx="2"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 8C7 7.44772 7.44772 7 8 7H16C16.5523 7 17 7.44772 17 8C17 8.55228 16.5523 9 16 9H8C7.44772 9 7 8.55228 7 8Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 12C7 11.4477 7.44772 11 8 11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13H8C7.44772 13 7 12.5523 7 12Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 16C7 15.4477 7.44772 15 8 15H16C16.5523 15 17 15.4477 17 16C17 16.5523 16.5523 17 16 17H8C7.44772 17 7 16.5523 7 16Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function SupportTicketIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_1923_33467" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 2C3.34315 2 2 3.34315 2 5V14C2 15.6569 3.34315 17 5 17H11.9365L16.3177 21.5249C16.6118 21.8286 17.0164 22 17.4391 22C18.3012 22 19 21.3012 19 20.4391V17C20.6569 17 22 15.6569 22 14V5C22 3.34315 20.6569 2 19 2H5Z"
        />
      </mask>
      <path
        d="M11.9365 17L13.3733 15.6088L12.7839 15H11.9365V17ZM16.3177 21.5249L17.7546 20.1337L17.7546 20.1337L16.3177 21.5249ZM19 17V15H17V17H19ZM4 5C4 4.44772 4.44772 4 5 4V0C2.23858 0 0 2.23858 0 5H4ZM4 14V5H0V14H4ZM5 15C4.44772 15 4 14.5523 4 14H0C0 16.7614 2.23858 19 5 19V15ZM11.9365 15H5V19H11.9365V15ZM17.7546 20.1337L13.3733 15.6088L10.4997 18.3912L14.8809 22.9161L17.7546 20.1337ZM17.4391 20C17.558 20 17.6719 20.0482 17.7546 20.1337L14.8809 22.9161C15.5517 23.6089 16.4748 24 17.4391 24V20ZM17 20.4391C17 20.1966 17.1966 20 17.4391 20V24C19.4057 24 21 22.4057 21 20.4391H17ZM17 17V20.4391H21V17H17ZM20 14C20 14.5523 19.5523 15 19 15V19C21.7614 19 24 16.7614 24 14H20ZM20 5V14H24V5H20ZM19 4C19.5523 4 20 4.44772 20 5H24C24 2.23858 21.7614 0 19 0V4ZM5 4H19V0H5V4Z"
        fill="currentColor"
        mask="url(#path-1-inside-1_1923_33467)"
      />
    </svg>
  );
}

export function InfoIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 8.99999C10.5523 8.99999 11 9.44771 11 9.99999V13.2727C11 13.825 10.5523 14.2727 10 14.2727C9.44771 14.2727 9 13.825 9 13.2727V9.99999C9 9.44771 9.44771 8.99999 10 8.99999Z" />
      <path d="M10 5.72727C9.44771 5.72727 9 6.17498 9 6.72727C9 7.27955 9.44771 7.72727 10 7.72727H10.0082C10.5605 7.72727 11.0082 7.27955 11.0082 6.72727C11.0082 6.17498 10.5605 5.72727 10.0082 5.72727H10Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.81818 9.99999C0.81818 4.92902 4.92902 0.818176 10 0.818176C15.071 0.818176 19.1818 4.92902 19.1818 9.99999C19.1818 15.071 15.071 19.1818 10 19.1818C4.92902 19.1818 0.81818 15.071 0.81818 9.99999ZM10 2.81818C6.03359 2.81818 2.81818 6.03359 2.81818 9.99999C2.81818 13.9664 6.03359 17.1818 10 17.1818C13.9664 17.1818 17.1818 13.9664 17.1818 9.99999C17.1818 6.03359 13.9664 2.81818 10 2.81818Z"
      />
    </svg>
  );
}
